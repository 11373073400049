import React from 'react';
import Feature  from '../../components/feature/Feature';
import './whatMWMResearch.css';

const WhatMWMResearch = () => {
  return (
    <div className="mwmresearch__whatmwmresearch section__margin" id="Whatmwmr">
      <div className="mwmresearch__whatmwmresearch-feature">
        <Feature title="Vad är MWM Research" text="Vårt mål är att informera och inspirera."/>
      </div>      
      <div className="mwmresearch__whatmwmresearch-heading">
        <h1 className="gradient__text">Möjligheterna är bortom din fantasi</h1>
        <p> Utforska databasen</p>
      </div>
      <div className="mwmresearch__whatmwmresearch-container">
        <Feature title="Databas" text="Med vår databas kan du få förstahandsinformation om makroekonomi."/>
        <Feature title="Geopolitik" text="Med vår geopolitiska inställning, få förstahandsinformation "/>
        <Feature title="Portfölj" text="Vi är transparenta med vår portfölj, ta en titt."/>
      </div>
    </div>
  )
}

export default WhatMWMResearch;