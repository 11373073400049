import React from 'react';
import './cta.css';

const CTA = () => {
  return (
    <div className="mwmresearch__cta">
      <div className="mwmresearch__cta-content">
        <p>Begär tidig åtkomst</p>
        <h3> Registrera dig idag och få åtkomst till databasen</h3>
      </div>
      <div className="mwmresearch__cta-btn">
        <button type="button">Sätt igång</button>
      </div>
    </div>
  )
}

export default CTA
 