import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title:'Förbättra din analys',
    text: 'Med hjälp av vår databas och geopolitiska analys kan du analysera datan bättre.',
  },
  {
    title:'Förbättra din analys',
    text: 'Med hjälp av vår databas och geopolitiska analys kan du analysera datan bättre.',
  },
  {
    title:'Förbättra din analys',
    text: 'Med hjälp av vår databas och geopolitiska analys kan du analysera datan bättre.',
  },
  {
    title:'Förbättra din analys',
    text: 'Med hjälp av vår databas och geopolitiska analys kan du analysera datan bättre.',
  },
];

const Features = () => {
  return (
    <div className="mwmresearch__features section__padding" id="features">
      <div className="mwmresearch__features-heading">
        <h1 className="gradient__text">Framtiden är nu och du behöver bara inse den. Gå med i MWM Research idag och få det att hända</h1>
        <p>Begär tidig åtkomst för att komma igång.</p>
      </div>
      <div className="mwmresearch__features-container">
        {featuresData.map((item,index) => (
          <Feature title={item.title} text={item.text} key={item.title + index}/>
        ))}
      </div>
    </div>
  )
}

export default Features