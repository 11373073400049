import React from 'react';
import './brand.css';

const Brand = () => {
  return (
    <div>
      Brand
    </div>
  )
}

export default Brand