import React from 'react';

import possibilityImage from '../../assets/bild36.png';
import './possibility.css';

const Possibility = () => {
  return (
    <div className="mwmresearch__possibility section__padding" id="possibility">
      <div className="mwmresearch__possibility-image">
        <img src={possibilityImage} alt="possibility"/>
      </div>
      <div className="mwmresearch__possibility-content">
        <h4>Begär tidig åtkomst för att komma igång</h4>
        <h1 className="gradient__text">Möjligheterna är bortom din fantasi</h1>
        <p>Följ med oss på MWM Research för att utforska förstahandsinformation och överträffa de finansiella obeslutsamheterna</p>
        <h4>Begär tidig åtkomst för att komma igång</h4>
      </div>
    </div>
  )
}

export default Possibility
