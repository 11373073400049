import React from 'react';
import './header.css';
import ai from '../../assets/bild12.png';

const Header = () => {
  return (
    <div className="mwmresearch__header section__padding" id="home">
      <div className="mwmresearch__header-content">
        <h1 className="gradient__text">Förstahands information med MWM Research</h1>
        <p> Marknaden bryr sig inte om var du gick i skolan, hur många bokstäver som finns bakom ditt namn eller vem din pappa är. Den bryr sig bara om ren konstnärlig skicklighet när det gäller att utvinna avkastning </p>

        <div className="mwmresearch__header-content__input">
          <input type="email" placeholder="Din e-postadress" />
          <button type="button">Sätt igång </button>
        </div>

        </div>
        <div className="mwmresearch__header-image">
          <img src={ai} alt="ai" />
      </div>
    </div>
  )
}

export default Header
