import React from 'react';
import './footer.css';
import logo from '../../assets/logo.png';
import { FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

function Footer() {
  return (
    <div className="mwmresearch__footer section__padding">
      <div className="mwmresearch__footer-heading">
        <h1 className="gradient__text">Vill du kliva in i framtiden före andra?</h1>
      </div>

      <div className="mwmresearch__footer-btn">
        <p>Begär tidig åtkomst</p>
      </div>

      <div className="mwmresearch__footer-links">
        <div className="mwmresearch__footer-links_logo">
          <img src={logo} alt="logo" />
          <p>MWM Research - Copyright 2024</p>
        </div>
        <div className="mwmresearch__footer-links_div">
          <h4>Hem</h4>
          <p>Databas</p>
          <p>Geopolitik</p>
          <p>Blogg</p>
        </div>
        <div className="mwmresearch__footer-links_div">
          <h4>Om</h4>
          <p>Logga in</p>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Kontakta oss</p>
        </div>
        <div className="mwmresearch__footer-links_div">
          <h4>Sociala Medier</h4>
          <a href="https://x.com/mwmresearch"><FaXTwitter /></a>
          <a href="https://www.linkedin.com/"><FaLinkedin /></a>
        </div>
      </div>

      <div className="mwmresearch__footer-copyright">
        <p>© 2024 MWM Research. All right reserved</p>
      </div>
    </div>
  );
}

export default Footer
