import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => {
  return (
    <div className="mwmresearch__blog section__padding" id="blog">
      <div className="mwmresearch__blog-heading">
        <h1 className="gradient__text">Det händer mycket, <br /> Vi bloggar om det.</h1>
      </div>
      <div className ="mwmresearch__blog-container">
        <div className="mwmresearch__blog-container_groupA">
        <Article imgUrl={blog01} date="Aug 4, 2024" text="AI är framtiden. Låt oss utforska hur det är?" />
      </div>
      <div className="mwmresearch__blog-container_groupB">
        <Article imgUrl={blog02} date="Aug 11, 2024" text="AI är framtiden. Låt oss utforska hur det är?" />
        <Article imgUrl={blog03} date="Aug 18, 2024" text="AI är framtiden. Låt oss utforska hur det är?" />
        <Article imgUrl={blog04} date="Aug 25, 2024" text="AI är framtiden. Låt oss utforska hur det är?" />
        <Article imgUrl={blog05} date="Sep 1, 2024" text="AI är framtiden. Låt oss utforska hur det är?" />
        </div>
      </div>
    </div>
  )
}

export default Blog
